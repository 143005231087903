.linkContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 20px;
}

.linkItem {
  flex: 1 0 30%;
  margin: 10px;
  flex-grow: 0;
}
