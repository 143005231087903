.shoeImage {
  width: 80% !important;
  height: 90%;
}

.shoeContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.shoeCard {
  flex: 1 1 250px;
  max-width: 250px;
  margin: 1%;
}

.shoeImageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
}

.shoesTitle {
  text-align: center;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
