.userContainer {
  padding: 10px;
  width: 100%;
}

.userPhoto {
  width: 33%;
  border-radius: 100%;
}

.userProfile {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mainUserInfoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
}

.username {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 5px;
}

.displayName {
  font-size: 20px;
}

.sport {
  font-size: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.followers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.followers div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heightWeight {
  margin-bottom: 5px;
  font-style: italic;
  font-size: 22px;
}

.userItems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
