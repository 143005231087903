.userStatContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40%;
  background-color: gainsboro;
  border-radius: 20px;
}

.userStats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.userScreen {
  margin: 20px;
}

.scrollableTable {
  height: 200px;
  overflow: auto;

  background-color: gainsboro;
  border-radius: 5px;
  padding: 20px;
  resize: vertical;
}

.searchForm {
  display: flex;
  flex-direction: row;
}

.userLogs {
  margin: 20px;
}

.searchResults {
  overflow: auto;
  resize: vertical;
  border-radius: 5px;
  height: 200px;
}

.heading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profilePhoto {
  border-radius: 50px;
  width: 50px;
  height: 50px;
  margin-right: 10px;
}
