.gameInfoCard {
  flex: 1;
}

.gameInfoContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}
