.game {
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gameLog {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.logHeader {
  margin-bottom: 10px;
  padding-left: 10px;
  text-align: center;
}

.logButton {
  align-items: center;
  display: flex;
  justify-content: center;
}
