.logo {
  display: flex;
  align-items: center;
}

.headerText {
  margin-left: 1em;
}

.margin {
  padding: 10px;
  width: 100%;
}

.inviteCodeHeader {
  text-align: center;
}

.teamsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
