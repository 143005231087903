.content {
  display: flex;
}

.form {
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex: 1;
}

.item {
  flex: 1;
}

.createInviteCodeButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
}

.selectImage {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 200px;
}
.card {
  flex: 1;
}

.imageSelected {
  width: 100%;
  height: 100%;
}

.formInput {
  flex: 1;
}